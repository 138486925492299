<template>
  <div>
    <!-- Note Create UI Element here. -->
    <note-create />
    <!-- Note List UI Element Here -->
    <note-list />
  </div>
</template>

<script>
import NoteCreate from '../../components/keep/note/NoteCreate.vue';
import NoteList from "../../components/keep/note/NoteList.vue";

export default {
  name: "NoteListView",
  components: { NoteList, NoteCreate },
};
</script>

<style scoped>
div {
  margin-top: 14px;
}
</style>