<template>
  <v-container fluid>
    <note-modal
      :open-modal="openModal"
      :note="selectedNote"
      @modalClosed="onModalClosed"
    />
    <note-update
      :note="selectedNoteForUpdate"
      :open-modal="openEditModal"
      @updateModalCollapse="
        (openEditModal = false), (selectedNoteForUpdate = {})
      "
    />
    <div class="text-center">
      <v-overlay :value="loading">
        <v-progress-circular
          v-if="loading"
          width="7"
          size="70"
          indeterminate
        />
      </v-overlay>
    </div>
    <v-row>
      <div
        class="not-found"
        v-if="allNoteList.length === 0 && !loading"
      >
        No notes found.
      </div>
      <v-col
        v-for="note in allNoteList"
        :key="note.id"
        :cols="true"
      >
        <each-note-card
          :note="note"
          @onToggleNoteExpansion="toggleNoteExpansion"
          @openEditModal="onOpenEditModal"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import EachNoteCard from "./card/EachNoteCard.vue";
import NoteModal from "./NoteModal.vue";
import NoteUpdate from "./NoteUpdate.vue";

export default {
  name: "NoteList",
  components: { EachNoteCard, NoteModal, NoteUpdate },
  computed: {
    ...mapGetters(["allNoteList", "notePagination"]),
  },
  data() {
    return {
      loading: false,
      selectedNote: {},
      selectedNoteForUpdate: {},
      openModal: false,
      openEditModal: false,
    };
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('getAllNotesFromAPI')
    this.loading = false
  },
  mounted () {
    this.registerScroll()
    if (this.$route.query.noteId) {
      this.getNoteOnQueryUpdate(this.$route.query.noteId)
    }
  },
  methods: {
    onOpenEditModal(note) {
      this.openEditModal = true;
      this.selectedNoteForUpdate = note;
    },
    toggleNoteExpansion (note) {
      this.$router.push({path: this.$route.path, query: {noteId: note.id} })
      this.selectedNote = note
      this.openModal = true
    },
    onModalClosed () {
      this.$router.push({path: this.$route.path})
      this.selectedNote = {}
      this.openModal = false
    },
    registerScroll() {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.getMoreNotes()
        }
      }
    },
    async getMoreNotes() {
      if (this.notePagination.next && !this.loading) {
        this.loading = true
        await this.$store.dispatch("getAllNotesFromAPI")
        this.loading = false
      }
    },
    async getNoteOnQueryUpdate (noteId) {
      const _note = this.allNoteList.find((item) => {
        return item.id === noteId
      })
      if (!_note) {
        const response = await this.$store.dispatch("getSingleNoteFromAPI", noteId)
        if (response && response.status === 200) {
          this.selectedNote = response.data
        } else {
          this.$ebus.$emit("newToastMessage", {
            message: response.msg,
            color: "error"
          });
        }
      } else {
        this.selectedNote = _note
      }
      this.openModal = true
    }
  },
  watch: {
    '$route.query.noteId' (newValue) {
      if (newValue) {
        this.getNoteOnQueryUpdate(newValue)
      }
    }
  }
};
</script>

<style scoped>
.row {
  margin: 0;
}
.not-found {
  margin: 5rem auto;
}
</style>